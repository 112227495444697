import { createAction, createStoreAction } from '@cobuildlab/react-simple-state';
import {
  easyDataAuthenticateErrorEvent,
  easyDataAuthenticateEvent,
  easyDataCreateEvent,
  easyDataCreateEventEvent,
} from './easy-data-events';
import axios from 'axios';
import { EASY_WALL_DATA_ENDPOINT } from './shared/easy-data-contants';
import { getEventData, getEventMeta } from './easy-data-utils';
import { eventMetaStore } from './event-data-stores';
import { EasyDataAuthenticateResponse, EasyDataEventCreate, EventMeta } from './easy-data-types';

export const easyDataAuthenticate = createAction(
  easyDataAuthenticateEvent,
  easyDataAuthenticateErrorEvent,
  async () => {
    let response;
    try {
      const eventData = await getEventData();
      console.log({ EASY_WALL_DATA_ENDPOINT });
      response = await axios.post<EasyDataAuthenticateResponse>(
        `${EASY_WALL_DATA_ENDPOINT}/authenticate`,
        {
          event_meta: getEventMeta(eventData),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (e) {
      console.log(e);
    }


    return {
      uuid: response?.data.uuid || '',
    };
  },
);

export const easyDataCreate = createAction(
  easyDataCreateEvent,
  easyDataCreateEventEvent,

  async (data: EasyDataEventCreate) => {

    const eventData = await getEventData();

    const response = await axios.post(
      `${EASY_WALL_DATA_ENDPOINT}/v2/create`,
      {
        name: data.name,
        user_id: data.user_id,
        event_meta: getEventMeta(eventData, data.extra_data),

      },
      {
        headers: {
          'Content-Type': 'application/json',
          'analytics-session': data.uuid,
        },
      },
    );

    return response.data;
  },
);

export const eventMetaStoreAction = createStoreAction(eventMetaStore, (prev, eventMeta: EventMeta[]) => ({
  ...prev,
  eventMeta,
}));
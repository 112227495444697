import { useCallback, useEffect, useState } from 'react';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { easyDataAuthenticate, easyDataCreate, eventMetaStoreAction } from './easy-data-actions';
import { universalCookies } from './shared/cookies';
import {
  EASY_DATA_COOKIE_OPTIONS,
  EASY_DATA_UUID_COOKIE,
} from './shared/easy-data-contants';
import { easyDataAuthenticateEvent } from './easy-data-events';

import { EasyDataCreateEventHook, EasyDataName, EventMeta } from './easy-data-types';
import { useAuth } from '../auth/auth-hook';
import { getEventData, getEventMeta } from './easy-data-utils';

export const useEasyDataAuthenticate = (): void => {
  const { uuid } = useEvent(easyDataAuthenticateEvent);
  const [checked] = useState(false);

  const callEventMeta = useCallback(async () => {
    const eventData = await getEventData();
    console.log(getEventMeta(eventData));
    eventMetaStoreAction(getEventMeta(eventData));
  }, []);

  const [callAction] = useCallAction(easyDataAuthenticate, {
    onCompleted: (data) => {
      universalCookies.set(
        EASY_DATA_UUID_COOKIE,
        data.uuid,
        EASY_DATA_COOKIE_OPTIONS,
      );
      console.log(`Usuario autenticado ${data.uuid}`);
    },
    onError: () => console.log('Error al autenticar'),
  });

  useEffect(() => {
    callEventMeta();
    if (!uuid) {
      callAction();
    }
  }, [uuid, checked, callAction, callEventMeta]);
};

export const useEasyDataEventCreate = (): EasyDataCreateEventHook => {
  const { uuid } = useEvent(easyDataAuthenticateEvent);
  const { user } = useAuth();

  const [callAction] = useCallAction(easyDataCreate, {
    onCompleted: () => {
      console.log('Evento registrado con éxito');
    },
    onError: () => console.log('Error al autenticar'),
  });

  const createAction = async (name: EasyDataName, extra_data?: EventMeta[]): Promise<void> => {
    if (!uuid) {
      console.log('Usuario no autenticado');
    } else {
      callAction({
        name: name,
        user_id: user?.id || 0,
        uuid: uuid,
        extra_data,
      });
    }
  };

  return {
    createAction,
  };
};

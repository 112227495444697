import React from 'react';
import { PlanItem } from './PlanItem';
import { Plan } from '../../../shared/types/generated';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { useAuth } from '../../auth/auth-hook';
import { useNavigate } from 'react-router-dom';
import { PlanLoading } from './PlanLoading';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '25px 0 10px 0',
      '& > .MuiTypography-root': {
        fontSize: 22,
        color: PRIMARY_COLOR,
      },
    },
    container: {
      border: '1px solid #FA0000',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      minHeight: 500,
      /**'&::before': {
        backgroundImage: 'url("/corazones.webp")',
        opacity: 0.4,
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        backgroundSize: 'cover',
      }, */
      /**'&::after': {
        content: '"Promoción Febrero"',
        position: 'absolute',
        top: -16,
        backgroundColor: PRIMARY_COLOR,
        color: 'white',
        fontSize: 20,
        padding: '0 20px',
        borderRadius: 15,
        lineHeight: 1.5,
        width: 180,
        fontWeight: 400,
        textAlign: 'center',
      }, */
    },

    subtitle: {
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > .MuiTypography-root': {
        color: 'white',
        backgroundColor: PRIMARY_COLOR,
        padding: '0 15px',
        borderRadius: 15,
        fontWeight: 'bold',
      },
    },
    price: {
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    description: {
      marginBottom: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > .MuiTypography-root': {
        fontSize: 22,
        fontWeight: 700,
      },
    },
    button: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 15,
      '& > .MuiButton-root': {
        background: '#FA0000!important',
        color: 'white!important',
        width: '180px',
        height: 45,
      },
    },
    text: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > .MuiTypography-root': {
        textAlign: 'center',
      },
    },
  }),
);

type PlanItemOneProps = {
  plan?: Plan;
  loading?: boolean;
};

export const PlanItemOne: React.FC<PlanItemOneProps> = ({ plan, loading }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();

  if (loading) return <PlanLoading containerClassName={classes.container} />;

  if (!plan) return null;

  return (
    <PlanItem
      priceText="pesos al mes."
      titleClassName={classes.title}
      containerClassName={classes.container}
      subTitleClassName={classes.subtitle}
      priceClassName={classes.price}
      descriptionClassName={classes.description}
      buttonClassName={classes.button}
      textButton="QUIERO PROBAR"
      imageOnCenter="/logo_month.png"
      helpTextContent={
        <>
          <Box mb={'10px'} className={classes.text}>
            <Typography fontWeight={700}>¡Cancela cuando quieras!</Typography>
          </Box>
          <Box mb={'20px'} className={classes.text}>
            <Typography fontSize={12} fontWeight={700}>
              *Se cobrarán $30 por los 3 primeros meses, <br /> y $99 a partir
              del 4to mes.
              {/**<br /> Solo con tarjeta de crédito/débito o Paypal. <br /> Válido
              hasta el 29 de febrero 2024. */}
            </Typography>
          </Box>
        </>
      }
      plan={plan}
      onClick={() => {
        if (user) navigate(`/metodos-de-pago/${plan?.id}`);
        else
          window.location.href = `${window.location.origin}/autenticacion?redirect_url=${window.location.origin}/metodos-de-pago/${plan?.id}`;
      }}
    />
  );
};

import React, { ReactNode } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { Plan } from '../../../shared/types/generated';

type PlanItemProps = {
  buttonClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  priceClassName?: string;
  containerClassName?: string;
  textButton?: string;
  descriptionClassName?: string;
  helpTextContent?: string | ReactNode;
  priceText?: string;
  extraGift?: string;
  textAbovePrice?: boolean;
  plan?: Plan;
  onClick: () => void;
  imageOnCenter?: string;
};

export const PlanItem: React.FC<PlanItemProps> = ({
  buttonClassName,
  plan,
  titleClassName,
  subTitleClassName,
  priceClassName,
  descriptionClassName,
  containerClassName,
  textAbovePrice,
  helpTextContent,
  extraGift,
  textButton,
  priceText = '',
  onClick,
  imageOnCenter,
}) => {
  return (
    <Box className={containerClassName}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={titleClassName}>
            <Typography>{plan?.title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={subTitleClassName}>
            <Typography>{plan?.sub_title}</Typography>
          </Box>
        </Grid>
        {textAbovePrice ? (
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            justifyItems={'center'}
            alignItems={'center'}
          >
            <Box className={descriptionClassName}>
              <Typography textAlign={'center'}>{plan?.description}</Typography>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}></Grid>
        )}
        <Grid item xs={12}>
          <Box className={priceClassName}>
            <Typography
              fontSize={'44px'}
              lineHeight={'50px'}
              fontWeight={'700'}
            >
              ${plan?.price}
            </Typography>
            {priceText && (
              <Typography width={'55px'} paddingLeft={1} fontSize={'12px'}>
                {priceText}
              </Typography>
            )}
          </Box>
        </Grid>

        {imageOnCenter ? (
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            justifyItems={'center'}
            alignItems={'center'}
          >
            <img
              src={imageOnCenter}
              alt="texto"
              height={150}
              width={150}
              style={{ objectFit: 'contain' }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} paddingBottom={'100px'}></Grid>
        )}

        {textAbovePrice ? (
          <>
            {extraGift && (
              <Grid
                item
                xs={12}
                display={'flex'}
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
              >
                <Box className={descriptionClassName}>
                  <Typography textAlign={'center'}>{extraGift}</Typography>
                </Box>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <Box className={descriptionClassName}>
              <Typography textAlign={'center'}>{plan?.description}</Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box className={buttonClassName}>
            <Button onClick={() => onClick()}>{textButton}</Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {helpTextContent}
      </Grid>
    </Box>
  );
};

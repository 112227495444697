import React from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import { useGoogleSignupMutation } from '../../../shared/types/generated';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { createProvider } from '../auth-utils';
import { openDialogAlert } from '../../alert/alert-actions';
import { useLocation } from 'react-router-dom';
import { useEasyDataEventCreate } from '../../easy-data/easy-data-hooks';
import { useEvent, useStore } from '@cobuildlab/react-simple-state';
import { eventMetaStore } from '../../easy-data/event-data-stores';
import { easyDataAuthenticateEvent } from '../../easy-data/easy-data-events';
import { EasyDataName } from '../../easy-data/easy-data-types';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  height: 45,
  paddingRight: '30px!important',
  border: '1px solid #FFCCCC!important',
  backgroundColor: 'white !important',
});

const StyledImage = styled('img')({
  marginRight: 10,
});

export const GoogleButton: React.FC = () => {
  const location = useLocation();
  const returnTo = location?.state?.returnTo as string | undefined;
  const [, setCookie] = useCookies([USER_COOKIE, 'analytics_uuid']);
  const { createAction } = useEasyDataEventCreate();
  const { eventMeta } = useStore(eventMetaStore);
  const { uuid } = useEvent(easyDataAuthenticateEvent);

  const [googleSignupCallback] = useGoogleSignupMutation({
    onCompleted: ({ googleSignup }) => {
      setCookie(USER_COOKIE, googleSignup?.token, COOKIES_OPTIONS);
      createProvider('Google');
      redirectToHome(returnTo ?? '/');
    },
    onError: () =>
      openDialogAlert('Tenemos problemas para identificar el usuario google'),
  });

  const googleLogin = useGoogleLogin({

    onSuccess: ({ access_token }) => {
      googleSignupCallback({
        variables: {
          token: access_token,
          easy_data: {
            uuid,
            event_meta: eventMeta,
          },
        },
      });
    },

    onError: () => openDialogAlert('Tenemos problemas con google'),
  });

  return (
    <>
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => {
          googleLogin();
          createAction(EasyDataName.gmailClicked);
        }}
        id="google-event-analytics"
        style={{ width: '100%', textTransform: 'none', fontWeight: 700 }}>
        <StyledImage src="/google-logo.png" width={20} height={20} alt="logo" />
        Continúa con Google
      </StyledButton>
    </>
  );
};



export type EasyDataAuthenticate = {
  uuid: string;
};
export type EasyDataAuthenticateResponse = {
  uuid: string;
  message: string;
};

export enum EasyDataName {
  loginClicked = 'login_clicked',
  registerClicked = 'register_clicked',

  facebookClicked = 'facebook_clicked',

  gmailClicked = 'gmail_clicked',

  appleClicked = 'apple_clicked',
}

export type EasyDataCreateEventHook = {
  createAction: (name: EasyDataName, extra_data?: EventMeta[]) => void;
};

export type EasyDataCreate = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  event?: { [key: string]: string }[];
};


export type IpInfo = {
  ip?: string;
  hostname?: string;
  city?: string;
  region?: string;
  country?: string;
  loc?: string;
  org?: string;
  timezone?: string;
}

export type GetEventData = {
  deviceType: string;
  browserName: string;
  ip: string;
  osName: string;
  categoryDevice: string;
  country: string;
  url: string;

  section: string;
  author: string;
  post_id: string;
  author_id: string;
}

export type EventMeta = {
  meta_key?: string;
  meta_value?: string;
}

export type EasyDataEventCreate = {
  user_id: number; uuid: string; name: EasyDataName, extra_data?: EventMeta[] }
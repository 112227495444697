import { EventMeta, GetEventData } from './easy-data-types';
import { publicIpv4 } from 'public-ip';
import { browserName, deviceType, isMobile, osName } from 'react-device-detect';
import moment from 'moment-timezone';
import { getName } from 'country-list';

const query = new URLSearchParams(window.location.search);

export const getCountryWithMoment = (): string => {
  const timezone = moment.tz.guess(true);
  const countries = moment.tz.countries();

  const country = countries.find((_country) =>
    moment.tz.zonesForCountry(_country).includes(timezone),
  );

  return getName(country as string) || '';
};

export const getEventData = async (): Promise<GetEventData> => {
  const ip = await publicIpv4();
  return {
    deviceType,
    browserName,
    ip,
    osName: osName.toLowerCase(),
    categoryDevice: isMobile ? 'mobile' : 'desktop',
    country: getCountryWithMoment(),
    url: query.has('redirect_url') ? (query.get('redirect_url') as string) : '',
    section: query.has('section') ? (query.get('section') as string) : '',
    author: query.has('author') ? (query.get('author') as string) : '',
    post_id: query.has('post_id') ? (query.get('post_id') as string) : '',
    author_id: query.has('author_id') ? (query.get('author_id') as string) : '',
  };
};

export const getEventMeta = (
  obj: any,
  extra_data: EventMeta[] = [],
): EventMeta[] => {
  if (typeof obj === 'object') {
    const array = Object.keys(obj).map((objKey) => ({
      meta_key: objKey,
      meta_value: obj[objKey] || '',
    }));

    return [...array, ...extra_data];
  }

  return [...extra_data];
};

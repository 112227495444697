import React, { useEffect, useState } from 'react';
import { Provider } from './AuthContext';
import { useEvent } from '@cobuildlab/react-simple-state';
import {
  COOKIES_OPTIONS,
  USER_COOKIE,
} from '../../shared/constans';
import { User, useCurrentUserLazyQuery } from '../../shared/types/generated';
import { universalCookies } from '../../shared/cookies';
import { OnTokenEvent } from './auth-events';
import { useNavigate } from 'react-router-dom';

type AuthProviderProps = {
  children?: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticate] = useState<boolean>(
    !!universalCookies.get(USER_COOKIE),
  );
  const [user, setUser] = useState<User | undefined | null>(undefined);
  const navigate = useNavigate();
  const { token } = useEvent(OnTokenEvent);


  const [currentUser] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false);
      setIsAuthenticate(true);
      setUser(data?.currentUser);
    },
    onError: () => {
      universalCookies.remove(USER_COOKIE, COOKIES_OPTIONS);
      OnTokenEvent.dispatch({ token: null });
      navigate('/autenticacion');
    },
  });

  useEffect(() => {
    if (token) {
      currentUser();
    } else {
      setLoading(false);
    }
  }, [currentUser, token]);

  const refetch = (): void => {
    currentUser();
  };

  const values = {
    isAuthenticated,
    isLoading,
    user,
    refetch,
    provider: universalCookies.get('_provider'),
  };

  return <Provider value={values}>{children}</Provider>;
};
